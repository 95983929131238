import { useEffect, useRef, useState, type FC } from 'react';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import { useResponsiveSize } from '@debatdirect/core-ts/hooks/useResponsiveSize';
import Button from '@debatdirect/core-ts/components/Button/Button';
import Card from '@debatdirect/core-ts/components/Card/Card';
import ChevronButton from '@debatdirect/core-ts/components/ChevronButton/ChevronButton';
import { CYCLE_MODE_STOP, TileSlider, type TileSliderRef } from '@videodock/tile-slider';
import '@videodock/tile-slider/lib/style.css';
import { ChevronLeft, ChevronRight } from '@debatdirect/core/icons';
import type { AppLocationsDict } from '@debatdirect/core-ts/types/app';
import { slugify } from '@debatdirect/core-ts/utils/formatter';
import { debateUrl } from '@debatdirect/core-ts/utils/routing';
import { type WatchHistoryItem } from '@debatdirect/core-ts/hooks/useWatchHistory';
import { CSSTransition } from 'react-transition-group';

import styles from './Shelf.module.scss';

type Props = {
  title: string;
  subtitle?: string;
  debates?: AppAgendaDebate[];
  locations?: AppLocationsDict;
  link?: string;
  watchHistory?: Record<string, WatchHistoryItem>;
  children?: React.ReactNode;
  onClearWatchHistoryClick?: (debate: AppAgendaDebate) => void;
};

const Shelf: FC<Props> = ({ title, subtitle, debates = [], locations, link, watchHistory = {}, onClearWatchHistoryClick, children }) => {
  const tilesToShowMap = { small: 1, phone: 2, tablet: 3, desktop: 4 };
  const tileSliderRef = useRef<TileSliderRef>(null);
  const size = useResponsiveSize();
  const tilesToShow = tilesToShowMap[size];
  const slugId = `title-${slugify(title)}`;
  const [visible, setVisible] = useState(debates.length > 0);
  const handleExitedCallback = useRef<() => void>();

  useEffect(() => {
    setVisible(debates.length > 0);
  }, [debates.length]);

  if (debates.length === 0 && !children) return null;

  return (
    <CSSTransition
      in={visible}
      timeout={500}
      enter={false}
      unmountOnExit
      classNames={{ exit: styles.slideExit, exitActive: styles.slideExitActive }}
      onExited={() => handleExitedCallback.current?.()}
    >
      <section aria-labelledby={slugId}>
        <div className={styles.topbar}>
          <div>
            <h2 className={styles.title} id={slugId}>
              {title}
            </h2>
            {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
          </div>
          {link && <Button href={link} label="Bekijk alle" iconRight={ChevronRight} className={styles.button} />}
        </div>
        {children || (
          <TileSlider<AppAgendaDebate>
            sliderRef={tileSliderRef}
            className={styles.slider}
            items={debates}
            tilesToShow={tilesToShow}
            spacing={16}
            pageStep={'tile'}
            showControls={true}
            cycleMode={CYCLE_MODE_STOP}
            renderLeftControl={({ onClick, disabled }) => (
              <ChevronButton icon={<ChevronLeft aria-hidden={true} />} title={'Vorige slide'} onClick={!disabled ? onClick : undefined} disabled={disabled} />
            )}
            renderRightControl={({ onClick, disabled }) => (
              <ChevronButton
                icon={<ChevronRight aria-hidden={true} />}
                title={'Volgende slide'}
                onClick={!disabled ? onClick : undefined}
                disabled={disabled}
              />
            )}
            renderTile={({ item, itemIndex, isVisible }) => {
              const location = locations?.[item.locationId];
              const cardLink = debateUrl(item, location);
              const watchHistoryItem = watchHistory[item.id];
              const progress =
                item.status === 'vod' && watchHistoryItem?.watchHistoryData?.progress
                  ? Math.round(watchHistoryItem.watchHistoryData.progress * 100)
                  : undefined;
              return (
                <Card
                  debate={item}
                  key={item.id}
                  location={location}
                  link={cardLink}
                  progress={progress}
                  onClearWatchHistoryClick={(item) => {
                    if (debates.length === 1) {
                      setVisible(false);
                      handleExitedCallback.current = () => onClearWatchHistoryClick?.(item);
                    } else {
                      onClearWatchHistoryClick?.(item);
                      // slideToIndex can cause flickering when removing the last item,
                      // but we need to slide to the previous item to prevent a gap
                      //tileSliderRef.current?.slideToIndex(Math.max(0, itemIndex - 1));
                    }
                  }}
                  tabIndex={isVisible ? undefined : -1}
                />
              );
            }}
            renderPagination={({ page, pages }) => {
              return (
                <div aria-live="polite" className="srOnly">
                  {`Pagina ${page + 1} van ${pages}`}
                </div>
              );
            }}
          />
        )}
      </section>
    </CSSTransition>
  );
};

export default Shelf;
