import React, { type FC, memo, useCallback } from 'react';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import type { AppAgendaDebate } from '@debatdirect/core-ts/types/debate';
import type { DebateLocation } from '@debatdirect/typings';
import { getDebateScreenshotUri, getDebateMetadata } from '@debatdirect/core-ts/utils/debate';
import StatusTag from '@debatdirect/core-ts/components/StatusTag/StatusTag';
import Image from '@debatdirect/core-ts/components/Image/Image';
import { navigate } from '@debatdirect/core-ts/utils/routing';
import InternalLink from '@debatdirect/core-ts/components/InternalLink/InternalLink';
import { trackEvent } from '@debatdirect/core-ts/utils/trackEvent';
import { Close } from '@debatdirect/core/icons';

import styles from './Card.module.scss';

type Props = {
  debate: AppAgendaDebate;
  location?: DebateLocation;
  link: string;
  tabIndex?: number;
  progress?: number;
  onClearWatchHistoryClick?: (debate: AppAgendaDebate) => void;
};

const Card: FC<Props> = ({ debate, location, link, tabIndex, progress, onClearWatchHistoryClick }) => {
  const { config } = useAppProvider();
  const alt = ''; // Intentionally empty because of adject text alternative

  const metadata = getDebateMetadata(debate);
  const imageUri = getDebateScreenshotUri(debate, location?.dynamicThumbnailUrl, config.screenshot);
  const fallbackImageUri = location?.cardImageUrl || location?.imageUrl;
  const meta = metadata.join(` • `); // Intentionally seperated by bullet, because of role="button" parent element
  const titleId = `card-title-${debate.id}`;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      navigate(link);
    }
  };

  const handleError = useCallback(() => {
    if (imageUri) {
      trackEvent('thumbnail error', debate.status, `${debate.id} / ${imageUri}`, 1);
    }
  }, [debate.id, debate.status, imageUri]);

  return (
    <div className={styles.card}>
      <InternalLink role="button" className={styles.cardInner} href={link} onKeyDown={handleKeyDown} tabIndex={tabIndex}>
        <div className={styles.description}>
          <h3 className={styles.title} id={titleId}>
            {debate.name}
          </h3>
          <p className={styles.meta}>{meta}</p>
        </div>
        <div className={styles.poster}>
          <Image id={debate.id} image={imageUri} fallbackImage={fallbackImageUri} alt={alt} className={styles.image} onError={handleError} />
          <StatusTag debate={debate} className={styles.tag} />
          {!!progress && <progress value={progress} max="100" className={styles.progress}></progress>}
        </div>
      </InternalLink>
      {onClearWatchHistoryClick && (
        <button
          className={styles.clearButton}
          type="button"
          title="Verwijderen uit kijkgeschiedenis"
          aria-describedby={titleId}
          onClick={() => onClearWatchHistoryClick(debate)}
        >
          <Close />
        </button>
      )}
    </div>
  );
};

export default memo(Card);
