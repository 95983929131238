import { useState, useEffect, useMemo } from 'react';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import { useAppProvider } from '@debatdirect/core-ts/lib/AppProvider';
import useAgendaWeeks from '@debatdirect/core-ts/hooks/useAgendaWeeks';
import { transformDebateData } from '@debatdirect/core-ts/utils/apiTransformers';
import { parseISO } from 'date-fns';
import { AppAgendaDebate } from 'types/debate';

type DebateWatched = {
  pdt: string;
  debateId: string;
  watchMoment: string;
  progress?: number;
};

export type WatchHistoryItem = AppAgendaDebate & { watchHistoryData: DebateWatched };

const MAX_ITEMS = 10;

const useWatchHistory = () => {
  const { getService } = useAppProvider();
  const { data, isLoading } = useAgendaWeeks();
  const api = getService('api');
  const [watchHistory, setWatchHistory] = useState<DebateWatched[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const agendaDebatesDict = Object.fromEntries((data ?? []).map((debate) => [debate.id, debate]));

  useEffect(() => {
    // TODO rewrite without using continue-watching factory
    setWatchHistory(getService('continue-watching').getHistory());
  }, [getService, refreshTrigger]);

  const refresh = () => {
    // TODO make reactive to changes within the watch history instead of using this temporary workaround
    setRefreshTrigger((prev) => prev + 1);
  };

  const mostRecentlyWatchedItems = watchHistory
    .filter((debate) => !debate.progress || (debate.progress >= 0.05 && debate.progress <= 0.95))
    .sort((a, b) => parseISO(b.watchMoment).getTime() - parseISO(a.watchMoment).getTime())
    .slice(0, MAX_ITEMS);

  const debates: WatchHistoryItem[] = useQueries({
    queries: mostRecentlyWatchedItems.map(({ debateId }) => ({
      queryKey: ['watchHistory', debateId],
      queryFn: agendaDebatesDict[debateId] ? () => agendaDebatesDict[debateId] : () => api.getDebateSummary(debateId).then(transformDebateData),
      enabled: !isLoading || !!agendaDebatesDict[debateId],
      retry: 0,
    })),
    combine: (results) => {
      return results
        .filter((query) => query.isSuccess)
        .map((query) => ({ ...query.data, watchHistoryData: watchHistory.find(({ debateId }) => debateId === query.data.id) }));
    },
  });

  return {
    debates,
    refresh,
  };
};

export default useWatchHistory;
